import ru from 'date-fns/locale/ru';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, Redirect } from 'react-router-dom';
import spinner from '../../assets/images/loader.gif';
import useFilterHook from '../../helpers/SortingRegistryHook';
import useModal from '../../helpers/useModal';
import { Page500 } from '../ErrorPage/ErrorPage';
import Collapsible from '../UI/Collapsible/CollapsibleComponent';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import Modal from '../UI/Modal/Modal';

const RegistryList = (props) => {
  const {
    catalogList,
    regions,
    subjects,
    educationForm,
    selectedEducationForm,
    categories,
    handleEducationFormChange,
    handleInputOrganisation,
    organisationSearch,
    sortTab,

    currentCards,
    searchFilter,
    handleInputSelect,
    searchAuthor,
    handleInputSearch,
    selectedCategoryTab,
    handleCategoryTabChange,
    selectedSubjectTab,
    handleSubjectTabChange,
    handleSortingTab,
    SortingAsc,
    selectedSortingTab,
    selectedInputSelect,
    onPageChanged,
    currentCardsPagination,

    selectedRegion,
    handleRegion,
    selectedSubject,
    handleSubject,
    startDate,
    endDate,
    handleStartDate,
    handleEndDate,

    submitFilter,
    queryParams,
    clearBtn,
    totalPages,
    handleFullSearch,
    fullSearch,

    loadingCards,
    isError,

    courseOfTrainingFrom,
    handleCourseOfTrainingFrom,
    courseOfTrainingTo,
    handleCourseOfTrainingTo,
  } = useFilterHook(props);
  const [filterToggle, setfilterToggle] = useState(false);
  return (
    <>
      <div className="RegistryPage__container">
        <div className="AboutPage__breadcrumbs">
          <div />
          <div>
            <Link to="/" className="FullProgramm__breadcrumbs__main-link">
              Главная
            </Link>
            <div className="FullProgramm__breadcrumbs__slash" />
            <div className="FullProgramm__breadcrumbs__second-link">
              Реестр программ
            </div>
          </div>
          <div />
        </div>
        <div className="RegistryPage">
          <div />
          <div>
            <div className="RegistryPage__title">
              Федеральный реестр образовательных программ дополнительного
              профессионального образования
            </div>
            {isError && <Redirect to="/500" component={Page500} />}
            {catalogList === null ? (
              <div className="spinner-wrapper">
                <img src={spinner} alt="" />
              </div>
            ) : null}
            {catalogList && (
              <div className="fade-animation">
                <div className="low-res-filers">
                  <Collapsible filters>
                    <div className="RegistryPage__upper-filter">
                      <div className="RegistryPage__search-container">
                        <input
                          type="text"
                          className="RegistryPage__search-select__input"
                          placeholder="Поисковое слово2 или запрос"
                          value={fullSearch}
                          onChange={handleFullSearch}
                        />
                      </div>
                      <div>
                        <CustomSelect
                          class="RegionSelect"
                          name="select"
                          items={regions}
                          onChange={handleRegion}
                          selectedValue={selectedRegion}
                          zIndex={12}
                        />
                      </div>
                    </div>
                    <div className="RegistryPage__middle-filter-upper">
                      <div className="RegistryPage__date-container">
                        Дата:
                        <div className="RegistryPage__date-category">
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartDate}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="От"
                            dateFormat="dd//MM/yyyy"
                            locale={ru}
                          />
                          <DatePicker
                            selected={endDate}
                            onChange={handleEndDate}
                            selectsEnd
                            endDate={endDate}
                            minDate={startDate}
                            placeholderText="До"
                            dateFormat="dd//MM/yyyy"
                            locale={ru}
                          />
                        </div>
                      </div>
                      <CustomSelect
                        class="CircleSelect"
                        name="select"
                        items={subjects}
                        onChange={handleSubject}
                        selectedValue={selectedSubject}
                        zIndex={10}
                      />
                    </div>
                    <div className="RegistryPage__middle-filter-lower">
                      <input
                        type="text"
                        className="filterInput "
                        placeholder="Автор"
                        value={searchAuthor}
                        onChange={handleInputSearch}
                      />
                      <input
                        type="text"
                        className="filterInput "
                        placeholder="Организация"
                        onChange={handleInputOrganisation}
                        value={organisationSearch}
                      />
                      <CustomSelect
                        class="CircleSelect"
                        name="select"
                        items={educationForm}
                        onChange={handleEducationFormChange}
                        selectedValue={selectedEducationForm}
                        zIndex={8}
                      />
                    </div>
                    <div className="RegistryPage__lower-filter">
                      <CustomSelect
                        class="CircleSelect"
                        name="select"
                        items={categories}
                        onChange={handleCategoryTabChange}
                        selectedValue={selectedCategoryTab}
                        zIndex={6}
                      />
                      
                      <div className="RegistryPage__time-container margin_top ">
                      {/* <div class="Two_name">  */}
                        Срок обучения:
                        <div className="RegistryPage__date-category">
                          <input
                            type="text"
                            className="filterInput"
                            placeholder="От"
                            value={courseOfTrainingFrom}
                            onChange={handleCourseOfTrainingFrom}
                            maxLength="3"
                          />
                          <input
                            type="text"
                            className="filterInput"
                            placeholder="До"
                            value={courseOfTrainingTo}
                            onChange={handleCourseOfTrainingTo}
                            maxLength="3"
                          />
                        </div>
                        <span className="RegistryPage__time-container__time-span">
                          / часов
                        </span>
                      {/* </div> */}
                      <div className="RegistryPage__sort-container ">
                        Cортировать:
                        <div className={`RegistryPage__arrow`} />
                        <span className="RegistryPage__sort-category">
                          <CustomSelect
                            class="AlphabetSelect"
                            name="select"
                            items={sortTab}
                            onChange={handleSortingTab}
                            selectedValue={selectedSortingTab}
                          />
                        </span>
                      </div>
                      </div>
                    </div>
                    <div className="buttons-container">
                      <button
                        className={`RegistryPage__check-btn ${
                          Object.keys(queryParams).length < 3 ? `disbl-btn` : ``
                        }`}
                        onClick={
                          Object.keys(queryParams).length > 2
                            ? clearBtn
                            : () => {}
                        }>
                        Очистить
                      </button>
                      <button
                        className="RegistryPage__check-btn"
                        onClick={() => submitFilter(queryParams)}>
                        Применить
                      </button>
                    </div>
                  </Collapsible>
                </div>

                <div className="hight-res-filers">
                  <div className="RegistryPage__upper-filter">
                    <div className="RegistryPage__search-container">
                      <input
                        type="text"
                        className="RegistryPage__search-select__input"
                        placeholder="Поисковое слово или запрос"
                        value={fullSearch}
                        onChange={handleFullSearch}
                      />
                    </div>
                    <div>
                      <CustomSelect
                        className="RegionSelect"
                        name="select"
                        items={regions}
                        onChange={handleRegion}
                        selectedValue={selectedRegion}
                        zIndex={12}
                      />
                    </div>
                  </div>
                  <div className="RegistryPage__middle-filter-upper">
                    <div className="RegistryPage__date-container">
                      Дата:
                      <div className="RegistryPage__date-category">
                        <DatePicker
                          selected={startDate}
                          onChange={handleStartDate}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          placeholderText="От"
                          dateFormat="dd//MM/yyyy"
                          locale={ru}
                        />
                        <DatePicker
                          selected={endDate}
                          onChange={handleEndDate}
                          selectsEnd
                          endDate={endDate}
                          minDate={startDate}
                          placeholderText="До"
                          dateFormat="dd//MM/yyyy"
                          locale={ru}
                        />
                      </div>
                    </div>
                    <CustomSelect
                      className="CircleSelect"
                      name="select"
                      items={subjects}
                      onChange={handleSubject}
                      selectedValue={selectedSubject}
                      zIndex={10}
                    />
                  </div>
                  <div className="RegistryPage__middle-filter-lower">
                    <input
                      type="text"
                      className="filterInput "
                      placeholder="Автор"
                      value={searchAuthor}
                      onChange={handleInputSearch}
                    />
                    <input
                      type="text"
                      className="filterInput "
                      placeholder="Организация"
                      onChange={handleInputOrganisation}
                      value={organisationSearch}
                    />
                    <CustomSelect
                      className="CircleSelect"
                      name="select"
                      items={educationForm}
                      onChange={handleEducationFormChange}
                      selectedValue={selectedEducationForm}
                      zIndex={8}
                    />
                  </div>
                  <div className="RegistryPage__lower-filter">
                    <CustomSelect
                      className="CircleSelect"
                      name="select"
                      items={categories}
                      onChange={handleCategoryTabChange}
                      selectedValue={selectedCategoryTab}
                      zIndex={6}
                    />
                    <div className="RegistryPage__time-container margin_top ">
                      Срок обучения:
                      <div className="RegistryPage__date-category">
                        <input
                          type="text"
                          className="filterInput"
                          placeholder="От"
                          value={courseOfTrainingFrom}
                          onChange={handleCourseOfTrainingFrom}
                          maxLength="3"
                        />
                        <input
                          type="text"
                          className="filterInput"
                          placeholder="До"
                          value={courseOfTrainingTo}
                          onChange={handleCourseOfTrainingTo}
                          maxLength="3"
                        />
                      </div>
                      <span className="RegistryPage__time-container__time-span">
                        / часов
                      </span>
                    </div>
                    <div className="RegistryPage__sort-container ">
                      Cортировать:
                      <div className={`RegistryPage__arrow`} />
                      <span className="RegistryPage__sort-category">
                        <CustomSelect
                          className="AlphabetSelect"
                          name="select"
                          items={sortTab}
                          onChange={handleSortingTab}
                          selectedValue={selectedSortingTab}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="buttons-container">
                    <button
                      className={`RegistryPage__check-btn ${
                        Object.keys(queryParams).length < 3 ? `disbl-btn` : ``
                      }`}
                      onClick={
                        Object.keys(queryParams).length > 2
                          ? clearBtn
                          : () => {}
                      }>
                      Очистить
                    </button>
                    <button
                      className="RegistryPage__check-btn"
                      onClick={() => submitFilter(queryParams)}>
                      Применить
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistryList;

const RegistryPageCard = ({
  id,
  expertise_theme,
  name,
  region,
  author_surname,
  author_name,
  author_patronymic,
  author_organization,
  education_form,
  additional_professional_program,
  pdf_dir,
  pdf_filename,
  pdf_realname,
  author_organization_site,
  ...props
}) => {
  const { isShowing, toggle, toggleBack } = useModal();
  return (
    <div className="RegistryPageCard">
      {/* {console.log("region", region)} */}
      <div className="RegistryPageCard__left-container">
        <div className="RegistryPageCard__category">
          Направленность программы
        </div>
        <div className="RegistryPageCard__sub-category">{expertise_theme}</div>
        <Link to={`/registry/detail/${id}`} className="RegistryPageCard__title">
          {name}
        </Link>
        <div className="RegistryPageCard__location">{region}</div>
        <div className="RegistryPageCard__lower-container">
          <div className="RegistryPageCard__lower-container__items card-name">
            {author_surname}
            <br />
            {author_name} {author_patronymic}
          </div>
          <div className="RegistryPageCard__lower-container__items">
            <a className="link" href={author_organization_site} target="_blank">
              {author_organization}
            </a>
          </div>
          <div className="RegistryPageCard__lower-container__items">
            Форма обучения: <span>{education_form}</span>
          </div>
        </div>
      </div>
      <div className="RegistryPageCard__right-container">
        <div className="RegistryPageCard__programm">
          <span>{additional_professional_program}</span>
        </div>
        <div className="RegistryPageCard__interactive">
          <span className="RegistryPageCard__view-btn" onClick={toggle}>
            смотреть
          </span>
          <a
            href={`${process.env.REACT_APP_API_URL}/uploads/${pdf_dir}/${pdf_filename}`}
            download={pdf_realname}
            className="RegistryPageCard__download-btn">
            скачать
          </a>
        </div>
      </div>
      <Modal toggleBack={toggleBack} isShowing={isShowing}>
        <iframe
          src={`${process.env.REACT_APP_API_URL}/uploads/${pdf_dir}/${pdf_filename}`}
          title="PortletIFrameWidget"
          className="iframe-modal"
        />
      </Modal>
      {/* )} */}
    </div>
  );
};

const items = [
  {
    title:
      'проектирование и разработка специальной индивидуальной программы развития для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'z индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 1,
    category_id: 1,
    subject_id: 4,
    input_id: 21,
  },
  {
    title:
      'разработка специальной индивидуальной программы развития для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'f профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Астраханская область',
    subject: 'Математика',
    id: 21,
    category_id: 2,
    subject_id: 5,
    input_id: 2,
  },
  {
    title:
      'специальной индивидуальной программы развития для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'l для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы',
    location: 'Хабаровская область',
    subject: 'Математика',
    id: 43,
    category_id: 1,
    subject_id: 5,
    input_id: 43,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 2,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 26,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 82,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 29,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 20,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 34,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'Последняя разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 99,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
];

const inputSelect = [
  { value: 'По содержанию', id: 0 },
  { value: 'Гуманитарное образование Естественнонаучное образование', id: 1 },
  { value: 'Математика', id: 21 },
  { value: 'Программирование и ИКТ', id: 43 },
  { value: 'Начальное образование', id: 2 },
];

const sortItems = [
  { value: 'по алфавиту', id: 1 },
  { value: 'по расположению', id: 21 },
  { value: 'по автору', id: 43 },
  { value: 'по предмету', id: 2 },
];

const subjects = [{ value: 'Астрономия', id: 1 }];

const categoryItems = [
  { id: 0, value: 'Все категории' },
  { id: 1, value: 'Повышение квалификации' },
  { id: 2, value: 'Переподготовка' },
];
